import React from "react";
import styled from "styled-components";
import { GameScreen } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import IdleTextInput from "../Components/IdleTextInput";
import {
  LargeHeading,
  MediumHeading,
  SmallHeading,
} from "../Components/TextTypes";
import { colors } from "../styles";
import { Highscore } from "./Lobby";

interface AnswerScreenProps {
  correctAnswer: number;
  correctAnswerText: string;
  myLastAnswer: number;
  highscore: Highscore;
  playerPlacement: number;
  correct: boolean;
  allHighscores: Highscore[];
  playerId: string | null;
  finished?: boolean;
  playerNick: string;
}

const formatPlacement = (placement: number) => {
  const j = placement % 10;
  const k = placement % 100;
  if (j === 1 && k !== 11) {
    return placement + "st";
  }
  if (j === 2 && k !== 12) {
    return placement + "nd";
  }
  if (j === 3 && k !== 13) {
    return placement + "rd";
  }
  return placement + "th";
};

export const AnswerScreen: React.FC<AnswerScreenProps> = (props) => {
  const { playerPlacement, correct, allHighscores, playerId, finished } = props;

  const calculateScoreDiff = () => {
    const last = allHighscores
      .at(-1)
      ?.find((user) => user.id === playerId)?.score;
    const secondLast = allHighscores
      .at(-2)
      ?.find((user) => user.id === playerId)?.score;

    if (last && secondLast) {
      return last - secondLast;
    } else if (last && !secondLast) {
      return last;
    } else {
      return 0;
    }
  };

  return (
    <AnswerWrapper correct={correct}>
      <Wrapper>
        <div style={{ display: "flex" }}>
          <LargeHeading>{correct ? "correct!" : "wrong!"} </LargeHeading>
          <div
            style={{
              marginLeft: "4px",
              transform: `rotateX(${correct ? "0deg" : "180deg"})`,
            }}
          >
            <LargeHeading>🎉</LargeHeading>
          </div>
        </div>

        <ScoreBox>
          <div style={{ opacity: 1 }}>
            {correct ? `+${calculateScoreDiff()} points` : ":("}
          </div>
        </ScoreBox>

        <BottomContent>
          <SmallHeading>
            {finished ? "You placed" : "You are in"}{" "}
            {formatPlacement(playerPlacement)}
          </SmallHeading>
          <IdleTextInput playerNick={props.playerNick} />
        </BottomContent>
      </Wrapper>
    </AnswerWrapper>
  );
};

const ScoreBox = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px 0px;
  text-align: center;
  width: 100%;
  max-width: 300px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 80vh;
`;

const BottomContent = styled.div`
  justify-content: flex-end;
  padding-bottom: 20px;
`;

interface AnswerWrapperProps {
  correct: boolean;
}

const AnswerWrapper = styled(GameScreen)`
  background-color: ${(props: AnswerWrapperProps) =>
    props.correct ? colors.green : colors.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;
