import React from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { MenuButton } from "../Components/Button";
import { LargeHeading } from "../Components/TextTypes";
import { socket } from "../context/socket";
import { colors, AlternativeColors } from "../styles";
import { Answer } from "./Admin";

interface QuestionProps {
  question: string;
  time: number;
  alternatives: string[];
  correctAnswer: Answer | null;
  playerCode: string;
  setScoreScreenState: (state: "answer" | "scores") => void;
}

const symbol = ["A", "B", "C", "D"];
const alternativeColors: AlternativeColors[] = [
  "red",
  "blue",
  "yellow",
  "green",
];

export const QuestionScreen: React.FC<QuestionProps> = (props) => {
  const {
    question,
    time,
    correctAnswer,
    alternatives,
    playerCode,
    setScoreScreenState,
  } = props;
  return (
    <Wrapper>
      <LargeHeading>
        {correctAnswer ? "Time's up!" : `Time ticks: ${time}`}
      </LargeHeading>
      <LargeHeading>Question: {question}</LargeHeading>
      <Border />
      <Alternatives>
        {alternatives.map((alternative, index) => {
          return (
            <Alternative
              key={index}
              correctAnswer={correctAnswer}
              alternative={alternativeColors[index]}
              answer={index}
            >
              <LargeHeading>
                {symbol[index]}: {alternative}
              </LargeHeading>
            </Alternative>
          );
        })}
      </Alternatives>
      <div style={{ position: "fixed", left: 0, top: 0 }}>
        {correctAnswer === null ? (
          <MenuButton
            onClick={() => {
              socket.emit("skip", { code: playerCode });
            }}
          >
            Skip question
          </MenuButton>
        ) : (
          <MenuButton onClick={() => setScoreScreenState("scores")}>
            Highscore -&gt;
          </MenuButton>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

interface AltProps {
  alternative: AlternativeColors;
  correctAnswer: Answer | null;
  answer: number;
}

const Alternative = styled.div`
  width: 45%;
  background-color: ${(props: AltProps) => colors[props.alternative]};
  opacity: ${(props: AltProps) => {
    const { correctAnswer, answer } = props;
    if (correctAnswer === null) {
      return 1;
    } else if (correctAnswer === answer) {
      return 1;
    } else {
      return 0.4;
    }
  }};
  margin: 24px;
  border-radius: 3px;
  border: 2px solid black;
`;

const Alternatives = styled.div`
  width: 80vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const Border = styled.hr`
  width: 90%;
`;
