import { useContext, FormEvent, useState, useEffect } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { MenuButton } from "../Components/Button";
import { Input } from "../Components/Input";
import { Logo } from "../Components/Logo";
import { SocketContext } from "../context/socket";
import { Alert, popcat } from "./ScreenSelector";

interface Props {
  roomCode: string;
  setRoomCode: Function;
  alert?: Alert;
}

export const JoinRoom = (props: Props) => {
  const { roomCode, setRoomCode, alert } = props;
  const socket = useContext(SocketContext);

  const [error, setError] = useState(alert?.severity === "error");

  useEffect(() => {
    setError(!!alert?.severity);
  }, [alert]);

  const emitJoin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    popcat.play();
    socket?.emit("join", {
      code: roomCode,
    });
  };

  return (
    <Wrapper animate>
      <Logo />
      <Box transparent onFormSubmit={emitJoin}>
        <Input
          error={error}
          onClick={() => setError(false)}
          placeholder={"Enter roomcode"}
          onChange={(e) => {
            setRoomCode(e.target.value);
          }}
          value={roomCode.toString()}
        />
        <MenuButton type="submit">Join room</MenuButton>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const ErrorWrapper = styled.div``;

const Error: React.FC = ({ children }) => <ErrorWrapper></ErrorWrapper>;
