import { FormEvent, useContext, useState } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { MenuButton } from "../Components/Button";
import { Input } from "../Components/Input";
import { LargeHeading } from "../Components/TextTypes";
import { SocketContext } from "../context/socket";
import { popcat } from "./ScreenSelector";

interface Props {
  roomTitle: string;
  saveNick: Function;
}

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const SetNick = (props: Props) => {
  const { roomTitle } = props;
  const socket = useContext(SocketContext);
  const [nick, setNick] = useState("");

  const emitNick = (e: FormEvent<HTMLFormElement>) => {
    popcat.play();
    e.preventDefault();
    socket?.emit("set nick", {
      nick,
    });
    props.saveNick(nick);
  };

  return (
    <Wrapper animate={true}>
      <LargeHeading>You joined: {roomTitle}</LargeHeading>
      <Box onFormSubmit={emitNick}>
        <Input
          placeholder="Set nick..."
          onChange={(e) => {
            setNick(e.target.value);
          }}
          value={nick.toString()}
        />
        <MenuButton type="submit">Join lobby</MenuButton>
      </Box>
    </Wrapper>
  );
};
