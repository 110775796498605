import IdleTextInput from "../Components/IdleTextInput";
import { LargeHeading, SmallHeading } from "../Components/TextTypes";
import usePageTracker from "../hooks/usePageTracker";

interface Props {
  playerNick: string;
}

export const Waiting: React.FC<Props> = ({ playerNick }) => {
  usePageTracker("waiting");

  return (
    <div
      style={{
        margin: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IdleTextInput playerNick={playerNick} />
      <LargeHeading>Waiting for the presenter</LargeHeading>
      <SmallHeading>Look for your name on the screen!</SmallHeading>
    </div>
  );
};
