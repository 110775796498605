import { error } from "console";
import styled from "styled-components";

interface Props {
  error?: boolean;
}

export const Input = styled.input`
  padding: 12px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 3px;
  border: 2px solid;
  border-color: ${({ error }: Props) =>
    error ? "var(--error)" : "var(--gray)"};
`;
