import { SocketContext, socket } from "./context/socket";
import { ScreenSelector } from "./Screens/ScreenSelector";
import { Router } from "@reach/router";
import { Admin } from "./Screens/Admin";

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <Router>
          <ScreenSelector path="/" />
          <Admin path="/admin" />
        </Router>
      </div>
    </SocketContext.Provider>
  );
}

export default App;
