import { useContext, useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { AnswerButton } from "../Components/Button";
import IdleTextInput from "../Components/IdleTextInput";
import { SmallHeading } from "../Components/TextTypes";
import { SocketContext } from "../context/socket";
import { Placement } from "./Placement";
import { popcat } from "./ScreenSelector";
import { Waiting } from "./Waiting";

interface Props {
  roomCode: string;
  playerNick: string;
  playerId: string | null;
}

export type User = {
  nick: string;
  score: number;
  id: string;
};

export type Highscore = User[];

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

type Action = { type: "append"; highscore: Highscore };

interface State {
  allHighscores: Highscore[];
}

function trackEvent(value: number, eventType: string) {
  type WindowPossiblyWithUmami =
    | (Window & { umami?: { trackEvent: Function } })
    | undefined;

  const colorByNumber = {
    0: "red",
    1: "blue",
    2: "yellow",
    3: "green",
  };

  (window as WindowPossiblyWithUmami)?.umami?.trackEvent(
    colorByNumber[value as 0 | 1 | 2 | 3],
    eventType
  );
}

const initialState: State = {
  allHighscores: [],
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "append":
      return {
        ...state,
        allHighscores: [...state.allHighscores, action.highscore],
      };
    default:
      return state;
  }
}

export const Lobby = (props: Props) => {
  const socket = useContext(SocketContext);
  const [time, setTime] = useState<number>(0);
  const [highscore, setHighscore] = useState<Highscore>([]);
  const [status, setStatus] = useState("waiting");
  const [answer, setAnswer] = useState(-1);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [myLastAnswer, setMyLastAnswer] = useState<number>(-1);
  const [correctAnswer, setCorrectAnswer] = useState<number>(-1);
  const [correctAnswerText, setCorrectAnswerText] = useState<string>("");

  useEffect(() => {
    socket.on("tick", (data) => {
      setTime(data.time);
    });

    socket.on("reset", () => {
      window.location.reload();
    });

    socket.on("highscore", (data) => {
      if (data.highscore) {
        setHighscore(data.highscore);
        if (data.status === "between question" || data.status === "finished") {
          dispatch({ type: "append", highscore: data.highscore });
        }
      }
      if (data.status) {
        setStatus(data.status);
        if (data.status === "start") {
          setAnswer(-1);
          setStatus("start");
        }
        if (data.status === "between question") {
          console.log("Previous question", data.lastAnswer.answer);
          setCorrectAnswer(data.lastAnswer.answer);
          setCorrectAnswerText(
            data.lastAnswer.alternatives[data.lastAnswer.answer]
          );
        }
        if (data.status === "finished") {
          console.log(
            "Correct answer for last question",
            data.lastAnswer.answer
          );
          //TODO: this is undefined finished is never broadcasted
          setCorrectAnswer(data.lastAnswer.answer);
          setCorrectAnswerText(
            data.lastAnswer.alternatives[data.lastAnswer.answer]
          );
        }
      }
    });
    socket.on("repeat", (data) => {
      console.log("From repeat", data);
    });
  }, []);

  const submitAnswer = (answer: number) => {
    popcat.play();
    socket.emit("answer", { answer });
    setAnswer(answer);
    setMyLastAnswer(answer);
    trackEvent(answer, "answer");
  };

  return (
    <Wrapper animate={false}>
      {/*<AnswerButton
        color="red"
        onClick={() => {
          socket.emit("repeat", {
            data: {
              truth: "carloerkul",
            },
          });
        }}
      >
        Broadcast Carlo er kul, to eeeveryone
      </AnswerButton>*/}

      {status === "lobby" && <h2>You are all set, wait for the host</h2>}
      {status === "between question" && (
        <Placement
          playState={status}
          highscore={highscore}
          allHighscores={state.allHighscores}
          playerNick={props.playerNick}
          correctAnswer={correctAnswer}
          myLastAnswer={myLastAnswer}
          correctAnswerText={correctAnswerText}
          playerId={props.playerId}
        />
      )}
      {status === "finished" && (
        <Placement
          playState={status}
          highscore={highscore}
          playerNick={props.playerNick}
          correctAnswer={correctAnswer}
          myLastAnswer={myLastAnswer}
          correctAnswerText={correctAnswerText}
          allHighscores={state.allHighscores}
          playerId={props.playerId}
        />
      )}
      {status !== "between question" && answer !== -1 && (
        <SmallHeading>Waiting...</SmallHeading>
      )}
      {(status === "waiting" || status === "user joined") && (
        <Waiting playerNick={props.playerNick} />
      )}
      {status === "start" && <H3>Time remaining: {time}</H3>}
      {status === "start" && answer === -1 && (
        <div>
          <ButtonGroup>
            <AnswerButton color="red" onClick={() => submitAnswer(0)}>
              A
            </AnswerButton>
            <AnswerButton color="blue" onClick={() => submitAnswer(1)}>
              B
            </AnswerButton>
          </ButtonGroup>
          <ButtonGroup>
            <AnswerButton color="yellow" onClick={() => submitAnswer(2)}>
              C
            </AnswerButton>
            <AnswerButton color="green" onClick={() => submitAnswer(3)}>
              D
            </AnswerButton>
          </ButtonGroup>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const H3 = styled.h3`
  color: white;
`;
