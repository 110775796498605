import { useContext, useState } from "react";
import styled from "styled-components";
import { SocketContext } from "../context/socket";
import { popcat } from "../Screens/ScreenSelector";
import { MenuButton } from "./Button";
import { Chatbox } from "./Chatbox";

interface Props {
  playerNick: string;
}

const IdleTextInput: React.FC<Props> = (props) => {
  const { playerNick } = props;
  const socket = useContext(SocketContext);
  const [textMsg, setTextMsg] = useState("");

  return (
    <form
      onSubmit={(e) => {
        popcat.play();
        e.preventDefault();
        if (textMsg.trim().length > 0) {
          socket.emit("idle message", { textMsg, playerNick });
          setTextMsg("");
        }
      }}
    >
      <Chatbox>
        <StyledInput
          value={textMsg}
          placeholder="Write a message for the big screen"
          onChange={(e) => {
            if (e.target.value.length > 100) {
              return;
            }
            setTextMsg(e.target.value);
          }}
        />
      </Chatbox>
      <div style={{ marginBottom: "20px" }}></div>
      <MenuButton type="submit">Enter</MenuButton>
    </form>
  );
};

const StyledInput = styled.textarea`
  border: none;
  min-height: 100px;
  width: 90%;
  resize: none;
`;

export default IdleTextInput;
