import { RouteComponentProps } from "@reach/router";
import {
  FormEvent,
  useContext,
  useEffect,
  useState,
  useRef,
  useReducer,
} from "react";
import { Input } from "../Components/Input";
import { SocketContext } from "../context/socket";
import styled, { keyframes } from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { Button, MenuButton } from "../Components/Button";
import { Highscore, User } from "./Lobby";
import {
  JumboHeading,
  LargeHeading,
  MediumHeading,
} from "../Components/TextTypes";
import ChatLayout from "../Components/ChatLayout";
import { popcat } from "./ScreenSelector";
import { QuestionScreen } from "./QuestionScreen";
import { HighscoreScreen } from "./Quizmaster/Highscores";
import { LobbyPlayers } from "../Components/LobbyPlayers";

export type ChatMessage = {
  msg: string;
  playerNick: string;
  id: string;
};

export type ChatHistory = ChatMessage[];

type Action =
  | { type: "append"; newMessage: string; playerNick: string; id: string }
  | { type: "clearHistory" }
  | { type: "appendHighscore"; newHighscores: Highscore };
interface State {
  chatHistory: ChatHistory;
  allHighscores: Highscore[];
}

const initialState: State = {
  chatHistory: [],
  allHighscores: [],
};

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "append":
      let newState = state.chatHistory.slice();
      if (state.chatHistory.length === 3) {
        newState.pop();
      }
      const newChatMsg = {
        msg: action.newMessage,
        playerNick: action.playerNick,
        id: action.id,
      };
      return {
        ...state,
        chatHistory: [newChatMsg, ...newState],
      };
    case "clearHistory":
      return {
        ...state,
        chatHistory: [],
      };
    case "appendHighscore":
      return {
        ...state,
        allHighscores: [...state.allHighscores, action.newHighscores],
      };
    default:
      return state;
  }
}

export type Answer = 0 | 1 | 2 | 3;
export const Admin = (props: RouteComponentProps) => {
  const socket = useContext(SocketContext);
  const [highscore, setHighscore] = useState<Highscore>([]);
  const [question, setQuestion] = useState<string>("");
  const [roomCode, setRoomCode] = useState("");
  const [playerCode, setPlayerCode] = useState("");
  const [alternatives, setAlternatives] = useState<string[]>([]);
  const [correctAnswer, setCorrectAnswer] = useState<Answer | null>(null);
  const [scoreScreenState, setScoreScreenState] = useState<"answer" | "scores">(
    "answer"
  );

  const [gameState, setGameState] = useState<
    "code" | "lobby" | "question" | "score" | "finished"
  >("code");
  const [time, setTime] = useState<number>(0);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [animateBackground, toggleAnimateBackground] = useState<boolean>(true);

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    console.log("GameState", gameState);
    if (gameState === "lobby") {
      //audioRef.current?.play();
    }
  }, [gameState, audioRef]);

  useEffect(() => {
    socket.on("highscore", (data) => {
      const newHighscore: Highscore = data.highscore;
      setHighscore(newHighscore);
      if (data.status === "between question") {
        dispatch({ type: "appendHighscore", newHighscores: newHighscore });
        setGameState("score");
      }
      if (data.status === "finished") {
        setGameState("finished");
      }
    });
    socket.on("question", (data) => {
      toggleAnimateBackground(false);
      setQuestion(data.question);
      setGameState("question");
      setScoreScreenState("answer");

      if (data.alternatives) {
        setCorrectAnswer(data.answer);
        setAlternatives(data.alternatives);
      }
    });
    socket.on("room you controll", (data) => {
      setPlayerCode(data.room);
      setGameState("lobby");
    });
    socket.on("not admin", () => {
      alert("not admin");
    });
    socket.on("tick", (data) => {
      setTime(data.time);
    });
    socket.on("show idle message", (data) => {
      dispatch({
        type: "append",
        newMessage: data.newMessage,
        playerNick: data.playerNick,
        id: data.id,
      });
    });
  }, []);

  const emitJoin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    socket?.emit("admin", { code: roomCode });
    setRoomCode("");
  };

  return (
    <AdminWrapper>
      <ChatLayout chatMsgs={state.chatHistory} />
      {gameState === "code" && (
        <Wrapper animate>
          <JumboHeading>Welcome quizmaster</JumboHeading>
          <Box onFormSubmit={emitJoin}>
            <Input
              placeholder={"Super secret admin code"}
              type="password"
              onChange={(e) => {
                setRoomCode(e.target.value);
              }}
              value={roomCode}
            />
            <MenuButton>Enter</MenuButton>
          </Box>
        </Wrapper>
      )}
      {gameState === "lobby" && (
        <AnimatedBackground animate>
          <LobbyContainer>
            <Top>
              <div>
                <LargeHeading style={{ width: "200px" }}>QuizIT!</LargeHeading>
                <MediumHeading>Players: {highscore.length}</MediumHeading>
              </div>
              <PersonaCard>
                <h3
                  style={{ textAlign: "center", margin: "0px", padding: "0px" }}
                >
                  ROOM CODE:
                </h3>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "64px",
                    margin: "0px",
                    marginTop: "8px",
                    padding: "0px",
                  }}
                >
                  {playerCode}
                </h3>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "36px",
                    margin: "0px",
                    marginTop: "12px",
                    padding: "0px",
                  }}
                >
                  YAQ.APP
                </h3>
                <MenuButton
                  onClick={() => {
                    popcat.play();
                    // Set initial highscore
                    dispatch({
                      type: "appendHighscore",
                      newHighscores: highscore,
                    });

                    socket.emit("next question", { code: playerCode });
                    setGameState("question");
                    setScoreScreenState("answer");
                    dispatch({ type: "clearHistory" });
                  }}
                >
                  Start!
                </MenuButton>
              </PersonaCard>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <RebootButton
                  onClick={() => {
                    socket.emit("reset game", { code: playerCode });
                    window.location.reload();
                  }}
                >
                  Reboot room ♻
                </RebootButton>
              </div>
            </Top>
            <LobbyPlayers highscore={highscore} />
          </LobbyContainer>
        </AnimatedBackground>
      )}
      {gameState === "question" && (
        <QuestionScreen
          question={question}
          alternatives={alternatives}
          correctAnswer={null}
          time={time}
          playerCode={playerCode}
          setScoreScreenState={setScoreScreenState}
        />
      )}
      {gameState === "finished" && scoreScreenState === "answer" && (
        <QuestionScreen
          question={question}
          alternatives={alternatives}
          correctAnswer={correctAnswer}
          time={time}
          playerCode={playerCode}
          setScoreScreenState={setScoreScreenState}
        />
      )}
      {gameState === "finished" && scoreScreenState === "scores" && (
        <Wrapper>
          <AnimatedBackground animate>
            <LargeHeading>Highscore - Game finished</LargeHeading>
            <HighscoreBoxLastScreen>
              {highscore
                .sort((a, b) => a.score - b.score)
                .reverse()
                .map((score: { nick: string; score: number }, index) => {
                  return (
                    <PlayerNameLastScreen key={index} index={index}>
                      <span style={{ width: "70px" }}>
                        {index === 0 && "⭐"}️{index === 1 && "🥈"}️
                        {index === 2 && "🥉"}
                      </span>
                      <HighscoreBar index={index}>
                        <span>
                          {score.nick.length > 20
                            ? score.nick.slice(0, 20) + "..."
                            : score.nick}{" "}
                        </span>
                        <span>{score.score} P</span>
                      </HighscoreBar>
                    </PlayerNameLastScreen>
                  );
                })}
            </HighscoreBoxLastScreen>
            <div style={{ position: "fixed", right: 10 }}>
              <Button
                onClick={() => {
                  socket.emit("reset game", { code: playerCode });
                  window.location.reload();
                }}
              >
                Leave the room
              </Button>
            </div>
          </AnimatedBackground>
        </Wrapper>
      )}
      {gameState === "score" && scoreScreenState === "answer" && (
        <QuestionScreen
          question={question}
          alternatives={alternatives}
          correctAnswer={correctAnswer}
          time={time}
          playerCode={playerCode}
          setScoreScreenState={setScoreScreenState}
        />
      )}
      {gameState === "score" && scoreScreenState === "scores" && (
        <HighscoreScreen
          allHighscores={state.allHighscores}
          playerCode={playerCode}
        />
      )}
      {gameState !== "code" && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <audio ref={audioRef} controls src="/yaqoot.mp3" loop />
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <RebootButton
              onClick={() => {
                socket.emit("reset game", { code: playerCode });
                window.location.reload();
              }}
            >
              Reboot room ♻
            </RebootButton>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};

const AdminWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;
const HighscoreBox = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 43vh;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  overflow-y: scroll;
`;
const HighscoreBoxLastScreen = styled.div`
  margin: 0 auto;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  overflow-y: scroll;
`;

export const HighscoreBar = styled.div`
  background-color: #99d367;
  padding: 20px 20px 20px 20px;
  margin: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 50%;
  ${(props: { index: number }) => props.index === 0 && "width: 80%;"}
  ${(props: { index: number }) => props.index === 1 && "width: 75%"}
  max-width: 1200px;
`;

const Players = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const PlayerName = styled.h3`
  display: flex;
  align-items: center;
  font-size: 28px;
  margin: 0px;
  padding: 5px 0px;
`;

const PlayerNameLastScreen = styled.h3`
  display: flex;
  align-items: center;
  font-size: 28px;
  line-height: 30px;
  ${(props: { index: number }) => props.index === 0 && "font-size: 42px;"}
  ${(props: { index: number }) =>
    props.index === 1 && "font-size: 36px; line-height: 40px;"}
`;

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8vh;
`;

const LobbyContainer = styled.div`
  padding: 20px;
`;

const RebootButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const personaTransition = keyframes`
  0% {
    clip-path: polygon(0 7%, 96% 0, 100% 94%, 5% 100%);
  } 
  25% {
    clip-path: polygon(0 7%, 76% 9%, 100% 94%, 5% 100%);
  }
  50% {
    clip-path: polygon(16% 2%, 90% 1%, 87% 86%, 5% 100%);  
  }
  75% {
    clip-path: polygon(16% 2%, 100% 13%, 80% 100%, 0 83%);
    }
  100%{
    clip-path: polygon(0 7%, 96% 0, 100% 94%, 5% 100%);
  }
`;

const PersonaCard = styled.div`
  margin-top: 96px;
  background-color: white;
  padding: 50px 160px;
  animation: 2.5s ${personaTransition} ease-in-out infinite;
`;
