import React, { useEffect } from "react";
import styled from "styled-components";
import { PlayerName } from "../Screens/Admin";
import { Highscore, User } from "../Screens/Lobby";
import { useTransition, useSpring, animated } from "react-spring";

interface Props {
  highscore: Highscore;
}

export const LobbyPlayers: React.FC<Props> = (props) => {
  const { highscore } = props;
  console.log("Highscore!", highscore);
  return (
    <StyledLobbyPlayers>
      {[...highscore].reverse().map((score: User, index: number) =>
        index === 0 ? (
          <AnimatedPlayer key={score.id} player={score} />
        ) : (
          <Player key={index}>
            <PlayerName>{score.nick}</PlayerName>
          </Player>
        )
      )}
    </StyledLobbyPlayers>
  );
};

interface FirstPlayerProps {
  player: User;
}

const AnimatedPlayer: React.FC<FirstPlayerProps> = (props) => {
  const { player } = props;
  console.log("First player", player);
  const range = [0, 0.7, 1];
  const output = [0, 2, 1];

  const { x } = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 300 },
  });

  useEffect(() => {
    console.log("New first player", props.player.id);
  }, [props.player.id]);

  return (
    <>
      <StyledAnimatedPlayer>
        <animated.div
          style={{
            transform: x.to({ range, output }).to((x) => `scale(${x})`),
            display: "inline-block",
          }}
        >
          <PlayerName>{player.nick}</PlayerName>
        </animated.div>
      </StyledAnimatedPlayer>
    </>
  );
};

const StyledLobbyPlayers = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 90%;
  height: 43vh;
  background-color: rgba(255, 255, 255, 0.6);
  overflow-y: scroll;
  padding: 24px;
`;

const Player = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  margin: 0px;
  text-align: start;
  width: 30%;
`;

const StyledAnimatedPlayer = styled.div`
  flex-grow: 1;
  padding: 10px 20px;
  margin: 0px;
  text-align: start;
  width: 30%;
`;
