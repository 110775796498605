import React from "react";
import styled from "styled-components";

export const JumboHeading = styled.h1`
  font-size: 128px;
  font-weight: 700;
  line-height: 146px;
  text-transform: uppercase;
`;

export const LargeHeading = styled.h2`
  font-size: 48px;
  line-height: 55px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const MediumHeading = styled.h3`
  font-size: 32px;
  line-height: 37px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const SmallHeading = styled.h4`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Normal = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

export const Small = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
`;

export const Label = styled.label`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;
