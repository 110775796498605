import React, { useState } from "react";
import styled from "styled-components";
import { socket } from "../context/socket";
import { AnswerScreen } from "./AnswerScreen";
import { Highscore } from "./Lobby";

interface Props {
  highscore: Highscore;
  playerNick: string;
  playState: string;
  correctAnswer: number;
  myLastAnswer: number;
  correctAnswerText: string;
  allHighscores: Highscore[];
  playerId: string | null;
}

export const Placement = (props: Props) => {
  const { highscore, correctAnswer, myLastAnswer } = props;
  const sorted = highscore.sort((a, b) => a.score - b.score).reverse();
  const playerPlacement = sorted.findIndex((user) => user.id === socket.id) + 1;
  const correct = myLastAnswer === correctAnswer;

  return (
    <>
      {props.playState === "between question" && (
        <AnswerScreen
          {...props}
          playerPlacement={playerPlacement}
          correct={correct}
        />
      )}
      {props.playState === "finished" && (
        <AnswerScreen
          {...props}
          finished
          playerPlacement={playerPlacement}
          correct={correct}
        />
      )}
    </>
  );
};
