import styled from "styled-components";
import {
  useTransition,
  animated,
  config,
  useSpring,
  useSprings,
} from "react-spring";
import { scaleLinear } from "d3-scale";
import { Highscore } from "../Lobby";
import { LargeHeading } from "../../Components/TextTypes";
import { MenuButton } from "../../Components/Button";
import { AnimatedBackground } from "../../Components/AnimatedBackground";
import { socket } from "../../context/socket";

interface Props {
  allHighscores: Highscore[];
  playerCode: string;
}

type ScoreListViz = {
  currentScore: number;
  previousScore: number;
  nick: string;
  id: string;
}[];

export const HighscoreScreen = (props: Props) => {
  const { allHighscores, playerCode } = props;
  console.log(allHighscores);
  const currentHighscore = allHighscores.at(-1) ?? [];
  const previousHighscore = allHighscores.at(-2) ?? [];

  const highestScore = Math.max(
    ...currentHighscore.map((score) => score.score)
  );

  // Map score to a screenWidth value
  const scale = scaleLinear([0, highestScore], [200, window.innerWidth - 300]);

  const vizList: ScoreListViz = currentHighscore.map((user, i) => {
    return {
      currentScore: user.score,
      previousScore:
        previousHighscore.find((pUser) => pUser.id === user.id)?.score ?? 0,
      nick: user.nick,
      id: user.id,
    };
  });

  const barHeight = 65;
  const oldPos = [...previousHighscore]
    .sort((a, b) => a.score - b.score)
    .reverse()
    .map((d) => d.id);
  const newPos = [...currentHighscore]
    .sort((a, b) => a.score - b.score)
    .reverse()
    .map((d) => d.id);

  // const transitions = useTransition(vizList, {
  //   keys: (d) => d.id,
  //   // from: (d, i) => ({
  //   //   width: scale(d.previousScore),
  //   //   y: oldPos.indexOf(d.id) * barHeight,
  //   // }),
  //   enter: (d, i) => [
  //     {
  //       width: scale(d.previousScore),
  //     },
  //     { y: oldPos.indexOf(d.id) * barHeight },
  //   ],
  //   update: (d, i) => [
  //     {
  //       width: scale(d.currentScore),
  //     },
  //     { y: newPos.indexOf(d.id) * barHeight },
  //   ],
  //   leave: (d, i) => [{ y: 10000 }, { width: 0 }],
  //   config: { ...config.molasses, duration: 500 },
  // });

  const springs = useSprings(
    vizList.length,
    vizList.map((d) => ({
      from: {
        width: scale(d.previousScore),
        y: oldPos.indexOf(d.id) * barHeight,
      },
      to: [
        { width: scale(d.currentScore) },
        { y: newPos.indexOf(d.id) * barHeight },
      ],
      config: { ...config.molasses, duration: 750 },
      delay: 800,
    }))
  );

  return (
    <Wrapper animate>
      <LargeHeading>Highscore</LargeHeading>
      <HighscoreBox>
        {/* {transitions((style, item, t, index) => (
          <animated.div key={index} style={{ ...style, position: "absolute" }}>
            <Bar {...item} />
          </animated.div>
        ))} */}
        {springs.map((style, i) => (
          <animated.div key={i} style={{ ...style, position: "absolute" }}>
            <Bar {...vizList[i]} />
          </animated.div>
        ))}
      </HighscoreBox>
      <div style={{ position: "fixed", right: 10 }}>
        <MenuButton
          onClick={() => {
            socket.emit("next question", { code: playerCode });
          }}
        >
          Next Question
        </MenuButton>
      </div>
    </Wrapper>
  );
};

interface BarProps {
  currentScore: number;
  previousScore: number;
  nick: string;
  id: string;
}

const Bar: React.FC<BarProps> = (props) => {
  const { currentScore, previousScore, nick, id } = props;

  const springProps = useSpring({
    from: { width: previousScore },
    to: { width: currentScore },
    delay: 800,
  });

  return (
    <HighscoreBar>
      <span>{nick.length > 20 ? nick.slice(0, 20) + "..." : nick} </span>
      <span>
        <animated.span>
          {springProps.width.to((x) => x.toFixed(0))}
        </animated.span>{" "}
        P
      </span>
    </HighscoreBar>
  );
};

const HighscoreBox = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 80vh;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  overflow-y: scroll;
  position: relative;
`;

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const HighscoreBar = styled.div`
  background-color: #99d367;
  padding: 20px 20px 20px 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;
